import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import LoadingSpinner from 'components/common/container/LoadingSpinner';
import Notification from 'components/common/container/Notification';
import ManualCorrection from 'components/view/manualCorrection/ManualCorrection';
import ManualCorrectorsDashboard from 'components/view/usersDashboard/ManualCorrectorsDashboard';
import Conference from 'components/view/conference/Conference';
import Providers from 'utils/Providers';
import 'react-toastify/dist/ReactToastify.css';
import 'App.css';
import 'index.scss';

const App = () => {
  return (
    <Providers>
      <LoadingSpinner />
      <Notification />
      <BrowserRouter>
        <Routes>
          <Route
            path="/:client/conference/*"
            element={<Navigate to="/conference" replace />}
          />
          <Route path="/conference/:queue?" element={<Conference />} />
          <Route
            path="/:client?/manualCorrection"
            element={<ManualCorrection />}
          />
          <Route
            path="/manualCorrectors"
            element={<ManualCorrectorsDashboard />}
          />
          <Route path="*" element={<Navigate to="/conference" replace />} />
        </Routes>
      </BrowserRouter>
    </Providers>
  );
};

export default App;

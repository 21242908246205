import {
  useParams,
  Navigate,
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import { useLogin, useEncryption } from '../../../utils/hooks';
import LoginComponent from '../auth/Login/LoginPage';
import Dashboard from './Dashboard';
import { preloadScript } from 'opentok-react';

function LoginLayout({ children }) {
  return <div className="app-wrapper">{children}</div>;
}

function BetaConference() {
  const { queue } = useParams();
  const navigate = useNavigate();
  const { isLogged, validatedUser, login, logout, token } = useLogin();
  const isReady = useEncryption({ token, isLogged });

  function handleLogin(loginData) {
    login(loginData);
    navigate(`/conference${queue ? `/${queue}` : ''}`);
  }

  if (!isLogged) {
    return (
      <LoginLayout>
        <Routes>
          {/* Correcting `log-in` path (relative to "/conference") */}
          <Route
            path="log-in"
            element={<LoginComponent onLogin={handleLogin} />}
          />

          {/* Redirect everything else to login if not logged in */}
          <Route
            path="*"
            element={
              <Navigate
                to={`log-in${
                  queue && queue !== 'log-in' ? `?queue=${queue}` : ''
                }`}
                replace
              />
            }
          />
        </Routes>
      </LoginLayout>
    );
  }

  if (!isReady || !validatedUser) return null;

  return (
    <Routes>
      {/* ⚠️ Change path from "/" to just an empty string "" */}
      <Route
        path=""
        element={
          <Dashboard logout={logout} isLogged={isLogged} token={token} />
        }
      />
      <Route path="*" element={<Navigate to="/conference" replace />} />
    </Routes>
  );
}

export default preloadScript(BetaConference);

import { useState, useRef, useEffect } from 'react';
import { createSession, preloadScript } from 'opentok-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Modal from 'react-modal';
import cx from 'classnames';

import ringSound from 'assets/mp3/ring.mp3';
import NewCustomer from './NewCustomer';
import { useInterval, useLogin } from '../../../utils/hooks';
import api from '../../../utils/api';
import * as repository from './InterviewRepository';
import { copyToClipboard } from '../../../utils/utils';
import {
  addFeedback,
  addNote,
  addUserConsent,
  getCustomFields,
  reportIssue,
} from 'services/omniRepository';
import { findInMockedSessions } from '../../../utils/utils/helpers';

import Header from '../../layout/header/Header';
import UserScreen from '../../widget/userScreen/UserScreen';
import ModuleMenu from './ModuleMenu';
import Module from './Module';
import AreYouSure from './AreYouSure';
import UserHungUp from '../../widget/userHungUp/UserHungUp';
import Connecting from './Connecting';
import CustomToast from '../../widget/customToast/CustomToast';
import { env } from '../../../env';
import risky from 'assets/img/risky.svg';
import { useParams } from 'react-router-dom';
import { refreshToken } from 'services/authRepository';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
const urlParams = window.location.pathname.split('/');
export const clientName = urlParams[1] || 'root';
export const loginStorageKey = `${clientName}ConferenceLoginData`;

async function sendEndEvents({
  interviewId,
  token,
  sessionId,
  status,
  selectedOption,
}) {
  let eventType;
  let details;

  if (!selectedOption) {
    if (status === 'APPROVED') {
      eventType = 'clientApproved';
      details = 'The client was approved';
    } else {
      eventType = 'clientRejected';
      details = 'The client was rejected';
    }
  } else {
    eventType = selectedOption[0];
    details = selectedOption[1].label;
  }

  await repository.addConferenceEvent({
    token: token,
    interviewId,
    opentokSessionId: sessionId,
    eventType,
    details,
    eventSource: 'executive',
  });

  await repository.addConferenceEvent({
    token: token,
    interviewId,
    opentokSessionId: sessionId,
    eventType: 'callFinished',
    details: 'The call was closed for any means',
    eventSource: 'executive',
  });
}
async function startArchive(interviewId, token) {
  return await api
    .post(
      '/omni/record-start',
      { interviewId },
      {
        token,
      },
    )
    .then(data => data)
    .catch(e => {
      throw new Error(e);
    });
}

async function stopArchive(
  queueName = '',
  openTokId,
  interviewId,
  status,
  token,
  selectedOption,
) {
  await api
    .post(
      '/omni/record-stop',
      {
        opentokArchiveId: openTokId,
        interviewId: interviewId,
        selectedOption: selectedOption,
      },
      {
        token,
      },
    )
    .then(data => data)
    .catch(e => {
      throw new Error(e);
    });

  api
    .put(
      `/omni/finish?queueName=${queueName}`,
      {
        interviewId,
        ...(status && { status }),
      },
      {
        token,
      },
    )
    .then(data => {
      //const url = data.urls.length > 0 ? data.urls[0] : '';
      //dispatch(archiveStopped(url));
    })
    .catch(e => {
      throw new Error(e);
    });
}

const ModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  color: black !important;
  width: 100%;
  border-radius: 20px 20px 0 0;
  height: 100px;
  padding: 20px;
`;

const ModalTitle = styled.h1`
  font-family: 'CircularStd-Bold';
  letter-spacing: 1px;
  color: #20263d;
`;

const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  min-height: 400px;
  background-color: #ededed;
  border-radius: 0 0 20px 20px;
`;

const SendButton = styled.button`
  margin-top: 15px;
  width: 100px;
  height: 50px;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  background-color: #00b2fd;

  &:disabled {
    opacity: 0.5;
    box-shadow: 0 6px 10px 0 rgba(18, 21, 28, 0.4);
    background-color: #757575;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;
  width: 100%;
  height: 60px;
  min-height: 60px;
  border-radius: 15px;
  background-color: #fff;
  font-family: 'CircularStd-Book';
  margin: 5px;
  color: #20263d;

  img {
    display: none;
  }

  &.selected {
    background-color: #20263d;
    color: #fff;
  }

  &.disabled {
    opacity: 0.5;
    background-color: #757575;
  }

  &:last-of-type {
    img {
      display: block;
    }
  }
`;

const OptionIcon = styled.img`
  margin-right: 10px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 10px;
  resize: none;
  padding: 15px;
  border: none;
  font-family: 'CircularStd-Book';
  margin-top: 5px;
`;

const interviewFinishedOptionsObj = {
  completed: {
    label: 'Interview completed',
    selected: false,
    disabled: true,
  },
  interruption: {
    label: 'Interruption by the client',
    selected: false,
  },
  communication: {
    label: 'Communication failed',
    selected: false,
  },
  risky: {
    label: 'Risky situation',
    selected: false,
  },
};

const externalLinksObj = {};

const mockedStatusObject = {
  sessionFinishedReason: {
    selectedOption: {
      label: 'Interview finished',
      selected: true,
    },
  },
  dataConfirmation: {
    name: true,
    birthDate: true,
    gender: true,
    address: true,
  },
  captureChecks: {
    selfie: true,
    back: true,
    front: true,
  },
};

function Dashboard({
  token,
  logout,
  sessionId,
  interviewToken,
  interviewCode,
}) {
  const { queue } = useParams();
  const audioElement = useRef();
  const [complete, setComplete] = useState(true);
  const [streams, setStreams] = useState([]);
  const [error, setError] = useState(null);
  const [connected, setConnected] = useState(false);
  const [sessionHelper, setSessionHelper] = useState(null);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [incorrectClientID, setIncorrectClientID] = useState(false);
  const [phone, setPhone] = useState('');
  const [interviewId, setInterviewId] = useState('');
  const [isAreYouSureOpen, setIsAreYouSureOpen] = useState(false);
  const [completeUserData, setCompleteUserData] = useState({});
  const [dataToReview, setDataToReview] = useState({});
  const [modules, setModules] = useState({
    info: {
      completed: false,
      current: true,
    },
    image: {
      completed: false,
      current: false,
    },
    ...(env.REACT_APP_ENCRYPT !== 'true' && {
      score: {
        completed: false,
        current: false,
      },
    }),
    notes: {},
    chat: {},
  });
  const [currentModule, setCurrentModule] = useState('info');
  const [areYouSureData, setAreYouSureData] = useState({});
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [clientConnected, setClientConnected] = useState(false);
  const [userHungUp, setUserHungUp] = useState(false);
  const [connectivityEvents, setConnectivityEvents] = useState([]);
  const [reported, setReported] = useState(false);
  const [isFinishSessionModalOpen, setIsFinishSessionModalOpen] =
    useState(false);
  const [interviewFinishedOptions, setInterviewFinishedOptions] = useState(
    interviewFinishedOptionsObj,
  );
  const [externalLinks] = useState(externalLinksObj);
  const [takenSelfie, setTakenSelfie] = useState(null);
  const [takenFront, setTakenFront] = useState(null);
  const [takenBack, setTakenBack] = useState(null);
  const [takenDocument, setTakenDocument] = useState(null);
  const [takenFrontFace, setTakenFrontFace] = useState(null);
  const [notes, setNotes] = useState('');
  const [showNotesTextarea, setShowNotesTextarea] = useState(false);
  const [riskySituationDetails, setRiskySituationDetails] = useState('');
  const [opentokId, setOpentokId] = useState(null);
  const [frontConfidence, setFrontConfidence] = useState(0);
  const [selfieConfidence, setSelfieConfidence] = useState(0);
  const [existingUser, setExistingUser] = useState(false);
  const [areNotesSaved, setAreNotesSaved] = useState(false);
  const [queueLength, setQueueLength] = useState(0);
  const { t } = useTranslation();
  const queueName = queue || '';
  const currentModuleRef = useRef('info');
  const [statusObject, setStatusObject] = useState({});
  const [manualIdCheckNeeded, setManualIdCheckNeeded] = useState(false);
  const [manualSelfieCheckNeeded, setManualSelfieCheckNeeded] = useState(false);
  const [conferenceConsent, setConferenceConsent] = useState({
    conferenceTermAndConditionsAccepted: false,
    creditBureauConsentGiven: false,
    applicantDataConfirmed: false,
    dataSent: false,
  });
  const [skipValidation, setSkipValidation] = useState(false);
  const [productName, setProductName] = useState();
  const [productId, setProductId] = useState();
  const loginInfo = useLogin();
  const { setLoginData } = loginInfo;
  const networkTestStartTime = useRef();
  const conferenceStatus = useRef('NO_CONFERENCE');

  useEffect(() => {
    if (findInMockedSessions(completeUserData?.curp)) {
      setSkipValidation(true);
    }
  }, [completeUserData]);

  useEffect(() => {
    if (skipValidation) {
      setInterviewFinishedOptions(interviewFinishedOptions => ({
        ...interviewFinishedOptions,
        completed: {
          label: 'Interview completed',
          selected: false,
          disabled: false,
        },
      }));
    }
  }, [skipValidation]);

  useEffect(() => {
    if (!statusObject?.sessionFinishedReason) return;

    sessionHelper.session.signal(
      {
        type: 'close',
        data: JSON.stringify(
          skipValidation &&
            statusObject?.sessionFinishedReason?.selectedOption?.label ===
              'Interview finished'
            ? mockedStatusObject
            : statusObject,
        ),
      },
      async error => {
        if (error) {
          console.log(`signal error (${error.name}): ${error.message}`); // eslint-disable-line no-console
        } else {
          console.log('close signal sent'); // eslint-disable-line no-console
        }

        await handleFinishSession(
          statusObject?.status === 'deny' ? 'REJECTED' : 'APPROVED',
          statusObject?.sessionFinishedReason?.selectedOption,
        );
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusObject]);

  function handleModuleChange(module) {
    if (module === 'image') {
      const dataPassed = Object.entries(dataToReview).every(
        item => item[1].passed,
      );

      setConferenceConsent(conferenceConsent => ({
        ...conferenceConsent,
        applicantDataConfirmed: dataPassed,
      }));
    }

    if (module === 'chat') {
      setUnreadMessages(0);
    }

    currentModuleRef.current = module;
    setCurrentModule(module);
  }

  function handleComplete(module) {
    setModules(modules => ({
      ...modules,
      [currentModule]: {
        ...modules[currentModule],
        completed: true,
        current: false,
      },
      [module]: {
        ...modules[module],
        completed: false,
        current: true,
      },
    }));
  }

  useInterval(async () => {
    try {
      await repository
        .getQueueSize({
          token,
        })
        .then(response => {
          setQueueLength(response.queueSize);

          if (response.queueSize > 0 && !sessionHelper) {
            audioElement.current.play();
          }
        });
    } catch (e) {
      if (!clientConnected) {
        logout();
        return;
      }
      if (e.response?.status === 403 || e.response?.status === 401) {
        const incodeRefreshToken = localStorage.getItem('incodeRefreshToken');
        const newLoginData = await refreshToken(incodeRefreshToken);
        localStorage.setItem(
          'incodeRefreshToken',
          newLoginData?.incodeRefreshToken,
        );
        setLoginData({
          token: newLoginData.token,
          clientId: newLoginData?.clientId,
          incodeRefreshToken: newLoginData?.incodeRefreshToken,
        });
      }
    }
  }, 3000);

  async function connectVideo() {
    const incodeRefreshToken = localStorage.getItem('incodeRefreshToken');
    const newLoginData = await refreshToken(incodeRefreshToken);
    localStorage.setItem(
      'incodeRefreshToken',
      newLoginData?.incodeRefreshToken,
    );
    setLoginData({
      token: newLoginData.token,
      clientId: newLoginData?.clientId,
      incodeRefreshToken: newLoginData?.incodeRefreshToken,
    });
    const session = await repository.getSession({ token, queueName });
    const fields = await getCustomFields(session?.interviewId, { token });

    setProductName(fields?.customFields?.productLabel);
    setProductId(fields?.customFields?.productId);

    if (!session.interviewId) {
      toast(<CustomToast status="customer-consumed" />);
      await sleep(1000);
      return;
    }

    setSession(session);

    setInterviewId(session.interviewId);

    setTotalSeconds(0);

    const { userData } = await repository.getUserData(session.interviewId, {
      token,
    });

    try {
      const sessionStatus = await repository.getSessionStatus(
        session.interviewId,
        {
          token,
        },
      );
      setManualIdCheckNeeded(sessionStatus?.manualIdCheckNeeded);
      setManualSelfieCheckNeeded(sessionStatus?.manualSelfieCheckNeeded);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }

    if (userData.phone) {
      setPhone(userData.phone);
    }

    setCompleteUserData(userData);

    const currentItem = getCurrentItem(userData);

    const fullName = userData?.fullNameMrz || userData?.name?.fullName;

    setDataToReview(prevState => ({
      ...prevState,
      name: {
        ...prevState.name,
        value: fullName,
        current: currentItem === 'name',
        reviewed: !fullName,
      },
      birthDate: {
        ...prevState.birthDate,
        value: userData?.birthDate,
        current: currentItem === 'birthDate',
        reviewed: !userData?.birthDate,
      },
      gender: {
        ...prevState.gender,
        value: userData?.gender,
        current: currentItem === 'gender',
        reviewed: !userData?.gender,
      },
      address: {
        ...prevState.address,
        value: userData?.address,
        current: currentItem === 'address',
        reviewed: !userData?.address,
      },
    }));

    setTotalSeconds(0);
    setComplete(false);
  }

  const getCurrentItem = obj => {
    if (obj?.name?.fullName) {
      return 'name';
    }
    if (obj?.birthDate) {
      return 'birthDate';
    }
    if (obj?.gender) {
      return 'gender';
    }
    if (obj?.address) {
      return 'address';
    }
  };

  function setSession(session) {
    if (!session.sessionId) {
      return;
    }

    const sessionHelper = createSession({
      apiKey: `${env.REACT_APP_TOKBOX_API}`,
      sessionId: session.sessionId,
      token: session.interviewToken,
      onStreamsUpdated: streams => {
        setStreams([...streams]);
      },
      onError: err => {
        setConnected(false);
        setError(`${t('connectFail')}: ${err.message}`);
      },
      onConnect: async () => {
        setConnected(true);
        const res = await startArchive(session.interviewId, token);
        setOpentokId(res.data.opentokArchiveId);
      },
      onDisconnect: async () => {
        setConnected(false);
        await stopArchive(queueName);
        setStreams([]);
      },
    });

    setSessionHelper(sessionHelper);

    sessionHelper.session.on('signal:msg', event => {
      const messageObject = JSON.parse(event.data);

      const newMessage = {
        date: Date.now(),
        body: messageObject.message,
        author: messageObject.author,
      };

      setMessages(messages => [...messages, newMessage]);

      if (
        messageObject.author === 'user' &&
        currentModuleRef.current !== 'chat'
      ) {
        setUnreadMessages(unreadMessages => unreadMessages + 1);
      }
    });

    sessionHelper.session.on('signal:terms', ev => {
      const { bureauAccepted, termsAccepted } = ev.data;

      setConferenceConsent(conferenceConsent => ({
        ...conferenceConsent,
        conferenceTermAndConditionsAccepted: termsAccepted,
        creditBureauConsentGiven: bureauAccepted,
        dataSent: true,
      }));
    });

    const { session: openTokSession } = sessionHelper;

    if (session) {
      // TODO: Remove most of console.log, going to be used atm to make it easier to debug
      openTokSession.on('signal:connect', async () => {
        console.log('session connected'); // eslint-disable-line no-console
        setClientConnected(true);
        setConnectivityEvents([]);
        await repository.addConferenceEvent({
          token: token,
          interviewId: session.interviewId,
          opentokSessionId: sessionId,
          eventType: 'clientConnected',
          details: 'Client and executive are connected to the stream',
          eventSource: 'executive',
        });
      });
      openTokSession.on('signal:user-disconnected', () => {
        console.log('user-disconnected'); // eslint-disable-line no-console
        setUserHungUp(true);
        setConnectivityEvents([]);
      });
      openTokSession.on('signal:connectSignal', async ev => {
        console.log('signal connected'); // eslint-disable-line no-console
        const creationTime = ev?.from?.creationTime;
        networkTestStartTime.current = creationTime;
        await repository.addConferenceEvent({
          token: token,
          interviewId: session.interviewId,
          opentokSessionId: sessionId,
          eventType: 'clientNetworkTest',
          details: 'Client start network test',
          eventSource: 'executive',
        });
      });
      openTokSession.on('connectionDestroyed', ev => {
        // eslint-disable-next-line no-console
        console.log(`The session disconnected. ${ev.reason}`);
        if (ev?.connection?.creationTime === networkTestStartTime.current)
          return;
        setUserHungUp(true);
      });
      openTokSession.on('signal:testInit', () => {
        console.log('signal: test-init'); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          t('connectivityChecks.customerStartedCheck'),
        ]);
      });
      openTokSession.on('signal:connectivityError', ev => {
        console.log(`signal:connectivityError -> ${ev.data}`); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          `${t('connectivityChecks.connectivityError')} ${ev.data}`,
        ]);
      });
      openTokSession.on('signal:checkingInternet', () => {
        console.log('checking internet'); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          t('connectivityChecks.stabilityCheck'),
        ]);
      });
      openTokSession.on('signal:internetNotStable', ev => {
        console.log('internet not stable', ev.data); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          t('connectivityChecks.unstableNetwork'),
        ]);
      });
      openTokSession.on('signal:internetStable', () => {
        console.log('internet stable'); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          t('connectivityChecks.internetStable'),
        ]);
      });
      openTokSession.on('signal:queueIndexErr', () => {
        console.log('queue index error'); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          t('connectivityChecks.unhandledQueueError'),
        ]);
      });
      openTokSession.on('signal:qualityError', ev => {
        console.log('quality error'); // eslint-disable-line no-console
        setConnectivityEvents(events => [
          ...events,
          t('connectivityChecks.unhandledQualityError'),
        ]);
      });
    }
  }

  function statusSwitch(option) {
    switch (option) {
      case 'communication':
      case 'interruption':
        return 'ABANDONED';
      case 'risky':
        return 'REJECTED_BY_RISK';
      case 'completed':
        return 'COMPLETED';
      case 'deny':
        return 'REJECTED';
      case 'approve':
        return 'APPROVED';
      default:
        return 'NO_CONFERENCE';
    }
  }

  function closeSession(status) {
    const selectedOption = Object.entries(interviewFinishedOptions).find(
      ([, value]) => value.selected,
    );

    conferenceStatus.current = statusSwitch(
      selectedOption ? selectedOption[0] : status,
    );

    setStatusObject(currentStatusObject => ({
      ...currentStatusObject,
      sessionFinishedReason: {
        selectedOption,
      },
      status,
    }));
  }

  async function handleFinishSession(status, selectedOption) {
    //TODO: Check why there are some cases where this function is called twice.
    if (notes) {
      addNote(interviewId, { notes }, token);
    }

    if (riskySituationDetails) {
      addFeedback(interviewId, { feedback: riskySituationDetails }, token);
    }

    addUserConsent(interviewId, conferenceConsent, token);

    await sendEndEvents({
      interviewId,
      token,
      sessionId: sessionHelper.session.sessionId,
      status,
      selectedOption,
    });

    await stopArchive(
      queueName,
      opentokId,
      interviewId,
      conferenceStatus.current,
      token,
      selectedOption ? selectedOption[1]?.label : '',
    );

    sessionHelper.session.signal(
      {
        type: 'conferenceFinished',
        data: JSON.stringify(statusObject),
      },
      error => {
        if (error) {
          console.log(`Signal error (${error.name}): ${error.message}`); // eslint-disable-line no-console
        } else {
          console.log('Conference finished signal sent'); // eslint-disable-line no-console
        }

        sessionHelper.disconnect();
      },
    );
    setComplete(true);
    setConnected(false);
    setStreams([]);
    setMessages([]);
    setClientConnected(false);
    setUserHungUp(false);
    setConnectivityEvents([]);
    setInterviewFinishedOptions(interviewFinishedOptionsObj);
    setIsAreYouSureOpen(false);
    setNotes('');
    setRiskySituationDetails('');
    setCurrentModule('info');
    setAreYouSureData({});
    setUnreadMessages(0);
    setReported(false);
    setIsFinishSessionModalOpen(false);
    setTakenFront(null);
    setTakenBack(null);
    setTakenDocument(null);
    setTakenFrontFace(null);
    setTakenSelfie(null);
    setShowNotesTextarea(false);
    setError(null);
    setSessionHelper(null);
    setTotalSeconds(0);
    setIncorrectClientID(false);
    setPhone('');
    setInterviewId('');
    setCompleteUserData({});
    setDataToReview({});
    setFrontConfidence(0);
    setSelfieConfidence(0);
    setExistingUser(false);
    setStatusObject({});
    setModules({
      info: {
        completed: false,
        current: true,
      },
      image: {
        completed: false,
        current: false,
      },
      score: {
        completed: false,
        current: false,
      },
      notes: {},
      chat: {},
    });
    setProductName(false);
    setProductId(false);
    setConferenceConsent({
      conferenceTermAndConditionsAccepted: false,
      creditBureauConsentGiven: false,
      applicantDataConfirmed: false,
      dataSent: false,
    });
    conferenceStatus.current = 'NO_CONFERENCE';
  }

  async function handleReportBug(bugReport) {
    const { session: openTokSession = {} } = sessionHelper;

    const responseStatus = await reportIssue(
      interviewId,
      openTokSession?.id,
      bugReport,
      token,
    );

    copyToClipboard(
      JSON.stringify({
        interviewId,
        sesionId: openTokSession?.id,
        bugReport,
      }),
    ).then(() => console.log('copied')); // eslint-disable-line no-console

    if (responseStatus.success) {
      setReported(true);
    }
  }

  function handleReview(name, value, dataValue, approved) {
    if (value === 'deny') {
      setStatusObject(currentStatusObject => ({
        ...currentStatusObject,
        dataConfirmation: {
          ...currentStatusObject.dataConfirmation,
          [name]: false,
        },
      }));
      setIsAreYouSureOpen(true);
      setAreYouSureData({ name, dataValue });
      return;
    }

    if (value === 'denied') {
      setStatusObject(currentStatusObject => ({
        ...currentStatusObject,
        dataConfirmation: {
          ...currentStatusObject.dataConfirmation,
          [name]: false,
        },
      }));
    } else {
      setStatusObject(currentStatusObject => ({
        ...currentStatusObject,
        dataConfirmation: {
          ...currentStatusObject.dataConfirmation,
          [name]: true,
        },
      }));
    }

    let key;

    if (name !== 'address') {
      for (const [entryKey] of Object.entries(dataToReview)) {
        if (entryKey === name) {
          const keys = Object.keys(dataToReview);
          const startIndex = keys.indexOf(entryKey) + 1;

          for (const nextKey of keys.slice(startIndex)) {
            if (dataToReview[nextKey]?.value) {
              key = nextKey;
              break;
            }
          }
          break;
        }
      }
    }

    if (key) {
      setDataToReview(dataToReview => ({
        ...dataToReview,
        [name]: {
          ...dataToReview[name],
          reviewed: true,
          passed: approved,
          current: false,
        },
        [key]: {
          ...dataToReview[key],
          current: true,
        },
      }));
    } else {
      setDataToReview(dataToReview => ({
        ...dataToReview,
        [name]: {
          ...dataToReview[name],
          reviewed: true,
          passed: approved,
          current: false,
        },
      }));
    }
  }

  function handleReviewClick(approved) {
    setIsAreYouSureOpen(false);
    setAreYouSureData('');
    handleReview(
      areYouSureData?.name,
      approved ? 'approved' : 'denied',
      areYouSureData?.dataValue,
      approved,
    );
  }

  const closeFinishSessionModal = () => {
    setIsFinishSessionModalOpen(false);
  };

  const handleInterviewFinishedOptionsClick = key => {
    if (interviewFinishedOptions[key].disabled) return;

    setShowNotesTextarea(key === 'risky');

    const newObj = Object.fromEntries(
      Object.entries(interviewFinishedOptions).map(([k, value]) => {
        return [k, { ...value, selected: k === key }];
      }),
    );

    setInterviewFinishedOptions(newObj);
  };

  const toggleFinishSessionModal = () => {
    setIsFinishSessionModalOpen(!isFinishSessionModalOpen);
    closeSession('deny');
  };

  const isAnyOptionSelected = () =>
    Object.values(interviewFinishedOptions).some(v => v.selected);

  const handleNotesChange = newNotes => setNotes(newNotes);

  const handleRiskySituationDetailsChange = newDetails =>
    setRiskySituationDetails(newDetails);

  const setSelfie = image => setTakenSelfie(image);

  if (incorrectClientID) {
    return (
      <div className="card-wrapper">
        <h1>{'Client ID does not exist.'}</h1>
      </div>
    );
  }

  return (
    <>
      <audio src={ringSound} ref={audioElement}>
        {t('noClientId')}
      </audio>
      <Modal
        isOpen={isFinishSessionModalOpen}
        onRequestClose={closeFinishSessionModal}
        contentLabel="Example Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <ModalTitleContainer>
          <ModalTitle>Interview finished</ModalTitle>
        </ModalTitleContainer>
        <ModalBodyContainer>
          {Object.keys(interviewFinishedOptions).map(k => {
            return (
              <Option
                key={k}
                className={cx({
                  selected: interviewFinishedOptions[k].selected,
                  disabled: interviewFinishedOptions[k].disabled,
                })}
                onClick={() => handleInterviewFinishedOptionsClick(k)}
              >
                <OptionIcon src={risky} />
                {interviewFinishedOptions[k].label}
              </Option>
            );
          })}
          {showNotesTextarea && (
            <Textarea
              value={riskySituationDetails}
              placeholder="Add more details"
              onChange={e => handleRiskySituationDetailsChange(e.target.value)}
            />
          )}
          <SendButton
            onClick={toggleFinishSessionModal}
            disabled={!isAnyOptionSelected()}
          >
            Send
          </SendButton>
        </ModalBodyContainer>
      </Modal>
      <Header
        sessionExist={sessionHelper ? !!sessionHelper.session : false}
        totalSeconds={totalSeconds}
        onConnect={connectVideo}
        handleLogOut={logout}
        handleReportBug={handleReportBug}
        reported={reported}
        setReported={setReported}
        setIsFinishSessionModalOpen={setIsFinishSessionModalOpen}
        queueLength={queueLength}
        loginInfo={loginInfo}
        queueName={queueName}
        productName={productName}
        productId={productId}
      />
      {sessionHelper ? (
        <>
          {sessionHelper.session &&
            connected &&
            (streams.length > 0 ? (
              <div className="userdata">
                {isAreYouSureOpen && (
                  <AreYouSure
                    dataValue={areYouSureData?.dataValue}
                    name={areYouSureData?.name}
                    handleReviewClick={handleReviewClick}
                  />
                )}
                <UserScreen
                  sessionId={sessionId}
                  interviewToken={interviewToken}
                  sessionHelper={sessionHelper}
                  error={error}
                  connected={connected}
                  streams={streams}
                  complete={complete}
                  name={
                    completeUserData?.fullNameMrz ||
                    completeUserData?.name?.fullName
                  }
                  interviewCode={interviewCode}
                  sendSMS={repository.sendSMS}
                  token={token}
                  phone={phone}
                  interviewId={interviewId}
                  clientConnected={clientConnected}
                  showNotification={() => {}}
                />
                <ModuleMenu
                  handleModuleChange={handleModuleChange}
                  currentModule={currentModule}
                  modules={modules}
                  unreadMessages={unreadMessages}
                  externalLinks={externalLinks}
                  frontId={completeUserData?.croppedFrontID}
                />
                <Module
                  frontId={completeUserData?.croppedFrontID}
                  backId={completeUserData?.croppedBackID}
                  dataToReview={dataToReview}
                  handleReview={handleReview}
                  session={sessionHelper?.session}
                  selfie={completeUserData?.selfie}
                  currentModule={currentModule}
                  handleModuleChange={handleModuleChange}
                  handleComplete={handleComplete}
                  modules={modules}
                  interviewId={interviewId}
                  token={token}
                  closeSession={closeSession}
                  messages={messages}
                  croppedIdFace={completeUserData?.croppedIDFace}
                  externalLinks={externalLinks}
                  setSelfie={setSelfie}
                  takenSelfie={takenSelfie}
                  notes={notes}
                  handleNotesChange={handleNotesChange}
                  takenFront={takenFront}
                  setTakenFront={setTakenFront}
                  takenFrontFace={takenFrontFace}
                  setTakenFrontFace={setTakenFrontFace}
                  document={completeUserData?.document}
                  takenBack={takenBack}
                  setTakenBack={setTakenBack}
                  takenDocument={takenDocument}
                  setTakenDocument={setTakenDocument}
                  existingUser={existingUser}
                  setExistingUser={setExistingUser}
                  frontConfidence={frontConfidence}
                  setFrontConfidence={setFrontConfidence}
                  selfieConfidence={selfieConfidence}
                  setSelfieConfidence={setSelfieConfidence}
                  areNotesSaved={areNotesSaved}
                  setAreNotesSaved={setAreNotesSaved}
                  poaName={completeUserData?.poaName}
                  proofOfAddress={completeUserData?.addressFromStatement}
                  setStatusObject={setStatusObject}
                  manualIdCheckNeeded={manualIdCheckNeeded}
                  manualSelfieCheckNeeded={manualSelfieCheckNeeded}
                  typeOfId={completeUserData?.typeOfId}
                  setInterviewFinishedOptions={setInterviewFinishedOptions}
                  skipValidation={skipValidation}
                  dataSent={conferenceConsent?.dataSent}
                />
              </div>
            ) : userHungUp ? (
              <UserHungUp
                title={t('customerLeftOrLostConnection')}
                subtitle={t('customerLeftOrLostConnectionInstructions')}
                sendSMS={repository.sendSMS}
                token={token}
                interviewId={interviewId}
                showNotification={() =>
                  toast(<CustomToast status="sms-sent" />)
                }
              />
            ) : (
              <Connecting
                sendSMS={repository.sendSMS}
                token={token}
                interviewId={interviewId}
                showNotification={() =>
                  toast(<CustomToast status="connecting" />)
                }
                connectivityEvents={connectivityEvents}
              />
            ))}
        </>
      ) : (
        <>
          {queueLength ? (
            <NewCustomer onConnect={connectVideo} />
          ) : (
            <div className="card-wrapper">
              <h1>{t('hi')}</h1>
              <p>{t('nextInterview')}</p>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default preloadScript(Dashboard);
